<template>
  <div class="container-password-setting">
    <div class="transbox">
      <form class="content" @submit.prevent="changePassword">
        <div class="header">
          <h1 class="header-name">mobican</h1>
          <h2 class="header-comment">パスワード設定画面</h2>
        </div>

        <div class="setting-password__text form-control">新しいパスワードを設定してください。</div>

        <!-- 現在のパスワード -->
        <div class="input-group">
          <input
            type="password"
            v-model="old_password"
            class="setting-password__input form-control"
            placeholder="現在のパスワード"
            @keypress="isPrevented($event)"
            @keyup.enter="changePassword"
          />
        </div>

        <!-- 新しいパスワード -->
        <div class="input-group">
          <input
            type="password"
            v-model="new_password"
            class="setting-password__input form-control"
            placeholder="新しいパスワード"
            @keypress="isPrevented($event)"
            @keyup.enter="changePassword"
          />
        </div>

        <!-- 新しいパスワード（確認用） -->
        <div class="input-group">
          <input
            v-model="confirm_password"
            type="password"
            class="setting-password__input form-control"
            placeholder="新しいパスワード（確認用）"
            @keypress="isPrevented($event)"
            @keyup.enter="changePassword"
          />
        </div>

        <!-- error message -->
        <div class="input-group" v-if="isSameOldPass">
          <small class="form-text text-danger error_msg">
            入力した現在のパスワードが一致しません。
          </small>
        </div>
        <div class="input-group" v-if="isWrongConfirmPass">
          <small class="form-text text-danger error_msg">
            新しいパスワード（確認用）が一致しません。
          </small>
        </div>

        <!-- submit button -->
        <button class="btn setting-password__submit form-control">設定</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsCookie from "js-cookie";

export default {
  name: "PasswordSetting",

  data() {
    return {
      old_password: null,
      new_password: null,
      confirm_password: null,
      isWrongInfo: false,
      remember: false,
      isSameOldPass: false,
      isWrongConfirmPass: false,
    };
  },
  methods: {
    changePassword() {
      this.isSameOldPass = false;
      this.isWrongConfirmPass = false
      let oldPassword = window.atob(jsCookie.get("loginPassword"))?.split("=")[1];

      // incase input old password is not the same with login password
      if (this.new_password === this.old_password || this.old_password !== oldPassword) {
        return (this.isSameOldPass = true);
      }

      // if confirm password is the same with new password and input old password same with login password
      if (this.confirm_password === this.new_password && this.old_password === oldPassword) {
        axios.defaults.headers.common = {
          "X-Api-Key": this.$store.state.headers["x-api-key"],
          Authorization: "Bearer " + jsCookie.get("token"),
        };

        // get user id and tenant id of login user
        let userLoginId = Number(window.atob(jsCookie.get("userId"))?.split("=")[1]);
        let tenantId = Number(jsCookie.get("tenantId"));

        let self = this;
        axios
          .put(
            this.$store.state.serverUrl +
              `resetting/v1?tenant_id=${tenantId}&user_id=${userLoginId}`,
            {
              password: this.new_password,
            }
          )
          .then(function () {
            self.removeCookiesData();
            alert("パスワードが変更されました。再度ログインしてください。");
            self.$router.push({ name: "Login" });
          })
          .catch((error) => {
            const errMsg = JSON.stringify(error.response.data.message);
            alert(errMsg);
          });
      } else {
        return (this.isWrongConfirmPass = true);
      }
    },

    removeCookiesData() {
      jsCookie.remove("token");
      jsCookie.remove("passwordInfo");
      jsCookie.remove("loginPassword");
      jsCookie.remove("tenantId");
      jsCookie.remove("employeeName");
      jsCookie.remove("employeeNumber");
      jsCookie.remove("userId");
      jsCookie.remove("intervalItem");
      jsCookie.remove("intervalTime");
      localStorage.removeItem("avatarImg");
    },

    isEmpty(value) {
      return value === "" || value === undefined || value === null;
    },
    isPrevented(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode < 64 || charCode > 90) &&
        (charCode < 97 || charCode > 122) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .transbox {
    width: 90%;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .transbox {
    width: 80%;
  }
}

@media (min-width: 993px) and (max-width: 1599px) {
  .transbox {
    width: 60%;
  }
}

@media (min-width: 1600px) {
  .transbox {
    width: 40%;
  }
}

.container-password-setting {
  font-family: Mina;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background: #f3fbfd;
}

.transbox {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: fixed;
}

.content {
  margin: 5% 15%;
}

.header-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  text-align: center;
  margin-bottom: 30px;
  color: #8bdff9;
}

.header-comment {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 37px;
}

.setting-password__input {
  height: 51.48px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 37px;
  padding-left: 22px;
  font-size: 24px;
}

.setting-password__text {
  border: none;
  color: #000000;
  background: rgba(255, 255, 255, 0.8);
  font-size: 24px;
  padding: 0;
}

.setting-password__input::-webkit-input-placeholder {
  color: #c4c4c4;
}

.setting-password__input:focus {
  border: 1px solid #3471b3;
}

.error_msg {
  font-size: 20px;
  margin-bottom: 37px;
  margin-top: -10px;
}

.setting-password__submit {
  background: #4e92f8;
  border-radius: 10px;
  color: white;
  font-size: 30px;
}

.setting-password__submit:hover {
  background-color: rgba(0, 45, 245, 0.829);
}

.setting-password__submit:disabled {
  background-color: #8f8c8c;
  color: #000000;
}
</style>
